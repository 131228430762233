@import url("https://fonts.googleapis.com/css?family=DM+Sans:500i,700&display=swap");

body {
  padding: 28px;
  background: #fffefa;
  animation: fadein 10.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landing-container {
  display: flex;
  justify-content: center;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
}

/* HEADER */

.landing-navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.landing-navbar-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.landing-navbar-logomark {
  height: 28px;
  width: 28px;
  border-radius: 20px;
  margin-right: 10px;
}

.landing-navbar-logo {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  text-decoration: none;
}

.landing-navbar-link-section {
  display: flex;
  font-size: 16px;
}

.landing-navbar-link {
  margin-left: 32px;
  color: #000;
  text-decoration: none;
}

.local-container {
  display: flex;
}
.new-tag {
  margin-left: 10px;
  background: #f55656;
  border-radius: 2px;
  padding: 3px 5px;
  font-size: 11px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.landing-navbar-link:hover {
  color: #747372;
}

/* TOP SECTION */

.landing-top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
  max-width: 520px;
  margin: auto;
}

.header-text {
  font-size: 28px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 16px;
}

.body-text {
  font-size: 22px;
  color: #747372;
  line-height: 32px;
  margin: auto;
}

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup-section {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 460px;
  margin: 32px auto 0 auto;
}

.signup-message {
  height: 30px;
  margin-top: 25px;
}

.signup-input {
  border: 1px solid rgba(7, 7, 7, 0.12);
  border-radius: 100px;
  width: 100%;
  padding: 10px 16px;
  margin-right: 8px;
  font-size: 16px;
  outline: none;
  background: none;
  -webkit-appearance: none;
}

.signup-input:hover {
  border: 1px solid rgba(7, 7, 7, 0.2);
}

.signup-input:focus {
  border: 1px solid rgba(7, 7, 7, 0.3);
}

.signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid rgba(16, 22, 26, 0.12);
  background: #f5f5f5;
  padding: 10px 16px;
  min-width: 130px;
  font-size: 16px;
}

.signup-button:hover {
  cursor: pointer;
  background: #f1f1f1;
}

.typeform-share {
  text-decoration: none;
  color: #2171d1;
}

.typeform-share:hover {
  color: #0a4fa1;
}

.landing-phone {
  width: 70%;
  min-width: 220px;
  max-width: 420px;
  margin: 35px auto 90px auto;
  filter: drop-shadow(rgba(15, 15, 15, 0.2) 0px 4px 6px);
}

.landing-features-section {
  margin: 0 -25px 70px;
}

.landing-header {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 24px;
}

.landing-feature-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.landing-feature {
  min-width: 275px;
  width: calc(50% - 50px);
  padding: 0 25px 32px;
  flex: 1;
}

.landing-feature-image {
  width: 100%;
  border: 1px solid rgba(7, 7, 7, 0.15);
  border-radius: 5px;
  box-shadow: 0 3px 7px 0 rgba(220, 220, 220, 0.35);
  margin-bottom: 20px;
}

.landing-feature-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.landing-feature-body {
  font-size: 16px;
  color: #747372;
  line-height: 26px;
}

/* ABOUT */

.landing-about-big-text {
  font-size: 20px;
  line-height: 34px;
}

.landing-about-container {
  display: flex;
  flex-wrap: wrap;
  margin: 42px -25px 0;
}

.landing-about-teammate {
  flex: 1;
  padding: 0 25px 32px;
  width: calc(50% - 25px);
  min-width: 275px;
}

.landing-about-photo {
  width: 72px;
  margin-bottom: 14px;
  border-radius: 3px;
}

.landing-about-teammate-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.landing-about-body {
  font-size: 16px;
  color: #747372;
  line-height: 26px;
}

.landing-about-body-link-container {
  margin-top: 7px;
  color: #747372;
}

.landing-about-body-link {
  color: #747372;
}

.landing-about-body-link:hover {
  color: black;
}

/* FOOTER */

.landing-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  margin: 110px auto 12px auto;
  font-size: 14px;
  color: #565656;
  border-top: 1px solid rgba(7, 7, 7, 0.15);
}

.footer-text {
  color: #747372;
  margin-left: 32px;
  text-decoration: none;
}

.footer-text:hover {
  color: #000;
}
